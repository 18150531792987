<template>
    <validation-observer ref="VForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD)">
        <b-card no-body>
          <b-card-header>
            <b-row>
              <b-col lg=8>
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
        </b-card>

        <b-tabs>
          <b-tab title="Content">
            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg=8>
                    <h5 class="card-title">Information Section</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="titleID">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input @input="createSlug($event, 'id')" v-model="row.mlp_title_id" placeholder="e.g. Gear Transmission"></b-form-input>
                      <VValidate name="Title (ID)" v-model="row.mlp_title_id" :rules="mrValidation.mlp_title_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="titleEN">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input @input="createSlug($event, 'en')" v-model="row.mlp_title_en" placeholder="e.g. Gear Transmission"></b-form-input>
                      <VValidate name="Title (EN)" v-model="row.mlp_title_en" :rules="mrValidation.mlp_title_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="titleJP">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input @input="createSlug($event, 'jp')" v-model="row.mlp_title_jp" placeholder="e.g. ギアトランスミッション"></b-form-input>
                      <VValidate name="Title (JP)" v-model="row.mlp_title_jp" :rules="mrValidation.mlp_title_jp" />
                    </b-form-group>
                  </b-col>
                  <!-- SLUG -->
                  <b-col md=4>
                    <b-form-group label-for="slugID">
                      <label>Slug (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mlp_slug_id"></b-form-input>
                      <VValidate name="Slug (ID)" v-model="row.mlp_slug_id" :rules="mrValidation.mlp_slug_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="titleEN">
                      <label>Slug (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mlp_slug_en"></b-form-input>
                      <VValidate name="Slug (EN)" v-model="row.mlp_slug_en" :rules="mrValidation.mlp_slug_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="titleJP">
                      <label>Slug (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mlp_slug_jp"></b-form-input>
                      <VValidate name="Slug (JP)" v-model="row.mlp_slug_jp" :rules="mrValidation.mlp_slug_jp" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="descID">
                      <label>Description (ID)</label>
                      <b-form-textarea :rows="3" v-model="row.mlp_description_id"></b-form-textarea>
                      <VValidate name="Description (ID)" v-model="row.mlp_description_id" rules="min:3" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="descID">
                      <label>Description (EN)</label>
                      <b-form-textarea :rows="3" v-model="row.mlp_description_en"></b-form-textarea>
                      <VValidate name="Description (EN)" v-model="row.mlp_description_en" rules="min:3" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="descID">
                      <label>Description (JP)</label>
                      <b-form-textarea :rows="3" v-model="row.mlp_description_jp"></b-form-textarea>
                      <VValidate name="Description (JP)" v-model="row.mlp_description_jp" rules="min:3" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group class="mt-3">
                      <label>Status<span class="text-danger mr5">*</span></label>
                      <b-form-radio-group
                        :options="$parent.Config.mr.StatusOptions"
                        v-model="row.mlp_is_active"
                      />
                      <VValidate 
                        name="Status" 
                        v-model="row.mlp_is_active" 
                        :rules="mrValidation.mlp_is_active" 
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg=8>
                    <h5 class="card-title">Image Section</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md=3>
                    <div class="file-uploader">
                      <label>Thumbnail <span class="text-danger mr5">*</span></label>
                      <Uploader v-model="row.mlp_thumbnail" type="thumbnail_lini_produk"/>
                      <VValidate 
                        name="Thumbnail" 
                        v-model="row.mlp_thumbnail" 
                        :rules="mrValidation.mlp_thumbnail" 
                      />
                    </div>
                  </b-col>
                  <b-col md=9 class="p-4 border">
                    <b-row>
                      <b-col lg="3" md="4" class="slider-item" v-for="(image, index) in row.mlp_images" :key="index">
                        <div class="card-img">
                          <b-img fluid class="card-img__media" :src="$parent.uploader(image.image)" :blank="!image.image" blank-color="#ccc"/>
                          <div class="bullet-cta">
                            <b-button
                              variant="secondary"
                              size="sm"
                              pill
                              class="btn-icon"
                              triggers="hover"
                              v-b-tooltip.hover="'Update'"
                              @click="editImage(image)"
                            >
                              <i class="fas fa-pencil-alt" />
                            </b-button>
                            <b-button
                              variant="danger"
                              size="sm"
                              pill
                              class="btn-icon"
                              v-b-tooltip.hover="'Delete'"
                              @click="deleteImage(index)"
                            >
                              <i class="far fa-trash-alt" />
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="4">
                        <a href="javascript:;" class="card-img-adder" @click="addImage">
                          <i class="fas fa-plus"></i>
                          <span>Add Image</span>
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg=8>
                    <h5 class="card-title">Development Section</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <div class="d-flex align-items-center">              
                  <b-form-checkbox id="showDevelopment" value="Y" unchecked-value="N" name="showDevelopment" v-model="row.mlp_show_development">Show Section?</b-form-checkbox>
                </div>
                <hr />
                <b-card no-body v-if="row.mlp_show_development == 'Y'">
                  <b-card-header>
                    <h5 class="card-title">Development Project</h5>
                  </b-card-header>
                  <b-card-body>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="titleDevID">
                          <label>Title (ID) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.development.title_id"></b-form-input>
                          <VValidate vid="dev_title_id" name="Title (ID)" v-model="row.mlp_content_development.development.title_id" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleDevEN">
                          <label>Title (EN) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.development.title_en"></b-form-input>
                          <VValidate vid="dev_title_en" name="Title (EN)" v-model="row.mlp_content_development.development.title_en" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleDevEN">
                          <label>Title (JP) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.development.title_jp"></b-form-input>
                          <VValidate vid="dev_title_jp" name="Title (JP)" v-model="row.mlp_content_development.development.title_jp" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="descDevID">
                          <label>Description (ID)</label>
                          <b-form-textarea v-model="row.mlp_content_development.development.desc_id"></b-form-textarea>
                          <VValidate vid="dev_desc_id" name="Description (ID)" v-model="row.mlp_content_development.development.desc_id" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descDevEN">
                          <label>Description (EN)</label>
                          <b-form-textarea v-model="row.mlp_content_development.development.desc_en"></b-form-textarea>
                          <VValidate vid="dev_desc_en" name="Description (EN)" v-model="row.mlp_content_development.development.desc_en" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descDevJP">
                          <label>Description (JP)</label>
                          <b-form-textarea v-model="row.mlp_content_development.development.desc_jp"></b-form-textarea>
                          <VValidate vid="dev_desc_jp" name="Description (JP)" v-model="row.mlp_content_development.development.desc_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
                <b-card no-body v-if="row.mlp_show_development == 'Y'">
                  <b-card-header>
                    <h5 class="card-title">Mass Production</h5>
                  </b-card-header>
                  <b-card-body>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="titleMassID">
                          <label>Title (ID) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.mass.title_id"></b-form-input>
                          <VValidate vid="mass_title_id" name="Title (ID)" v-model="row.mlp_content_development.mass.title_id" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleMassEN">
                          <label>Title (EN) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.mass.title_en"></b-form-input>
                          <VValidate vid="mass_title_en" name="Title (EN)" v-model="row.mlp_content_development.mass.title_en" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleMassEN">
                          <label>Title (JP) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mlp_content_development.mass.title_jp"></b-form-input>
                          <VValidate vid="mass_title_jp" name="Title (JP)" v-model="row.mlp_content_development.mass.title_jp" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card class="border">
                      <b-row class="mt-2 gutter-3">
                        <b-col md=4 v-for="(value, index) in row.mlp_content_development.mass.lists" :key="index">
                          <b-row class="align-items-center border p-2">
                            <b-col md=9>
                              <p class="m-0">{{ value.title_id }}</p>
                            </b-col>
                            <b-col md=3>
                              <div class="d-flex justify-content-end text-right">
                                <b-button
                                  variant="outline-warning"
                                  pill
                                  @click="editMass(value)"
                                  class="btn-icon mr-2"
                                  v-b-tooltip.hover="'Update'"
                                >
                                  <i class="fas fa-pencil-alt" />
                                </b-button>
                                <b-button
                                  variant="outline-danger"
                                  pill
                                  class="btn-icon"
                                  @click="deleteMass(index)"
                                  v-b-tooltip.hover="'Delete'"
                                >
                                  <i class="fas fa-trash" />
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col>
                          <b-button type="button" variant="outline-success" @click="addMass">Add Mass Production</b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-card-body>
                </b-card>
              </b-card-body>
            </b-card>

            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg=8>
                    <h5 class="card-title">Product Section</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <div class="d-flex align-items-center">              
                  <b-form-checkbox id="showGear" value="Y" unchecked-value="N" name="showGear" v-model="row.mlp_show_gear">Show Section?</b-form-checkbox>
                </div>
                <hr />
                <template v-if="row.mlp_show_gear == 'Y'">
                  <div class="d-flex align-items-center mb-2">              
                    <b-button variant="info" class="btn-rounded ml-auto" @click="addFlagshipProduct">
                      Add Flagship Product <i class="fas fa-plus ml-2"></i>
                    </b-button>
                  </div>
                  <b-row>
                    <div v-for="(value, index) in row.mlp_content_gear" :key="index" class="col-md-3">
                      <div class="facilities_wrap">
                        <img :src="$parent.uploader(value.image)" class="thumb_prod" alt="">
                        <div :class="'overlay_facilities ' + (index % 2 == 0 ? 'bg_red_ic' : 'bg_green_ic')">
                          <h3>{{value.title_id}}</h3>
                        </div>
                        <div class="wrap_fasilitas_action">
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="deleteFlagship(index)"
                            pill
                            class="btn-icon"
                            triggers="hover"
                            v-b-tooltip.hover="'Delete'"
                          >
                            <i class="fas fa-trash" />
                          </b-button>
                          <b-button
                            variant="warning"
                            size="sm"
                            pill
                            @click="updateFlagship(value, index)"
                            class="btn-icon"
                            triggers="hover"
                            v-b-tooltip.hover="'Update'"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </template>
              </b-card-body>
            </b-card>

            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg=8>
                    <h5 class="card-title">Fasilitas Peralatan</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <div class="d-flex align-items-center">              
                  <b-form-checkbox id="showFasilitas" value="Y" unchecked-value="N" name="showFasilitas" v-model="row.mlp_show_fasilitas">Show Section?</b-form-checkbox>
                </div>
                <hr />
                <template v-if="row.mlp_show_fasilitas == 'Y'">
                  <b-row>
                    <b-col md=4>
                      <b-form-group label-for="titleFasilitasID">
                        <label>Title (ID) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.mlp_content_fasilitas.title_id"></b-form-input>
                        <VValidate vid="fasilitas_title_id" name="Title (ID)" v-model="row.mlp_content_fasilitas.title_id" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="titleFasilitasEN">
                        <label>Title (EN) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.mlp_content_fasilitas.title_en"></b-form-input>
                        <VValidate vid="fasilitas_title_en" name="Title (EN)" v-model="row.mlp_content_fasilitas.title_en" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="titleFasilitasEN">
                        <label>Title (JP) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.mlp_content_fasilitas.title_jp"></b-form-input>
                        <VValidate vid="fasilitas_title_jp" name="Title (JP)" v-model="row.mlp_content_fasilitas.title_jp" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col md=4>
                      <b-form-group label-for="descFasilitasID">
                        <label>Description (ID)</label>
                        <b-form-textarea v-model="row.mlp_content_fasilitas.desc_id"></b-form-textarea>
                        <VValidate vid="fasilitas_desc_id" name="Description (ID)" v-model="row.mlp_content_fasilitas.desc_id" rules="min:3" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="descFasilitasEN">
                        <label>Description (EN)</label>
                        <b-form-textarea v-model="row.mlp_content_fasilitas.desc_en"></b-form-textarea>
                        <VValidate vid="fasilitas_desc_en" name="Description (EN)" v-model="row.mlp_content_fasilitas.desc_en" rules="min:3" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="descFasilitasJP">
                        <label>Description (JP)</label>
                        <b-form-textarea v-model="row.mlp_content_fasilitas.desc_jp"></b-form-textarea>
                        <VValidate vid="fasilitas_desc_jp" name="Description (JP)" v-model="row.mlp_content_fasilitas.desc_jp" rules="min:3" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-card class="border p-4">
                    <b-row>
                      <b-col lg="3" md="4" class="slider-item mb-2" v-for="(value, index) in row.mlp_content_fasilitas.lists" :key="index">
                        <div class="card-img">
                          <b-img fluid class="card-img__media" :src="$parent.uploader(value.image)" :blank="!value.image" blank-color="#ccc"/>
                          <span class="card-img__title">{{ value.title_id }}</span>
                          <div class="bullet-cta">
                            <b-button
                              variant="secondary"
                              size="sm"
                              pill
                              class="btn-icon"
                              triggers="hover"
                              v-b-tooltip.hover="'Update'"
                              @click="editFasilitas(value)"
                            >
                              <i class="fas fa-pencil-alt" />
                            </b-button>
                            <b-button
                              variant="danger"
                              size="sm"
                              pill
                              class="btn-icon"
                              v-b-tooltip.hover="'Delete'"
                              @click="deleteFasilitas(index)"
                            >
                              <i class="far fa-trash-alt" />
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="3" md="4">
                        <a href="javascript:;" class="card-img-adder" @click="addFasilitas">
                          <i class="fas fa-plus"></i>
                          <span>Add Fasilitas</span>
                        </a>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-card-body>
            </b-card>

            <b-card no-body>
              <b-card-footer>
                <b-row>
                  <b-col lg="12" class="text-right">
                    <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
                    <b-button type="submit" variant="primary" class="btn-rounded" @click="$parent.doSubmitCRUD('VForm', $refs)">Save Changes</b-button>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-tab>
          <b-tab title="SEO Settings">
            <b-card no-body>
              <b-card-header>
                <b-card-title title-tag="h5">SEO Settings</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-tabs>
                  <b-tab title="ID">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title ID<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mlp_seo_title_id" />
                            <VValidate 
                              name="Meta Title ID" 
                              v-model="row.mlp_seo_title_id" 
                              :rules="{...mrValidation.mlp_seo_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description ID<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mlp_seo_desc_id" />
                            <VValidate 
                              name="Meta Desc ID" 
                              v-model="row.mlp_seo_desc_id" 
                              :rules="{...mrValidation.mlp_seo_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTags">
                            <template #label>
                              Meta Keywords ID
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mlp_seo_keywords_id" />
                            
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                  <b-tab title="EN">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title EN<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mlp_seo_title_en" />
                            <VValidate 
                              name="Meta Title EN" 
                              v-model="row.mlp_seo_title_en" 
                              :rules="{...mrValidation.mlp_seo_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description EN<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mlp_seo_desc_en" />
                            <VValidate 
                              name="Meta Desc EN" 
                              v-model="row.mlp_seo_desc_en" 
                              :rules="{...mrValidation.mlp_seo_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTags">
                            <template #label>
                              Meta Keywords EN
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mlp_seo_keywords_en" />
                            
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                  <b-tab title="JP">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title JP<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mlp_seo_title_jp" />
                            <VValidate 
                              name="Meta Title JP" 
                              v-model="row.mlp_seo_title_jp" 
                              :rules="mrValidation.mlp_seo_title_jp" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description JP<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mlp_seo_desc_jp" />
                            <VValidate 
                              name="Meta Desc JP" 
                              v-model="row.mlp_seo_desc_jp" 
                              :rules="mrValidation.mlp_seo_desc_jp" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTags">
                            <template #label>
                              Meta Keywords JP
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mlp_seo_keywords_jp" />
                            
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                </b-tabs>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>

                
      </b-form>

      <b-modal id="modalImage" title="Form Image" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormImage"
        >
          <b-form @submit.prevent="handleSubmit(submitImage)">
            <b-card-body>
              <b-row>
                <b-col md=12>
                  <div class="form-group">
                    <label class="control-label">Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="dataModal.image" type="lini_produk"/>
                    <VValidate 
                      name="Image" 
                      v-model="dataModal.image"
                      rules="required" 
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="submitImage" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modalMass" size="xl" title="Form Mass Production" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormMass"
        >
          <b-form @submit.prevent="handleSubmit(submitMass)">
            <b-card-body>
              <b-row>
                <b-col md=4>
                  <b-form-group label-for="massTitleId">
                    <label>Title (ID) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_id"></b-form-input>
                    <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="massTitleEn">
                    <label>Title (EN) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_en"></b-form-input>
                    <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="massTitleEn">
                    <label>Title (JP) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_jp"></b-form-input>
                    <VValidate name="Title (JP)" v-model="dataModal.title_jp" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="submitMass" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modalMachine" size="lg" title="Form Machine" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormMachine"
        >
          <b-form @submit.prevent="handleSubmit(submitMachine)">
            <b-card-body>
              <b-row>
                <b-col md=4>
                  <b-form-group label-for="massTitleId">
                    <label>Title (ID) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_id"></b-form-input>
                    <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="massTitleEn">
                    <label>Title (EN) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_en"></b-form-input>
                    <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="massTitleEn">
                    <label>Title (JP) <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="dataModal.title_jp"></b-form-input>
                    <VValidate name="Title (JP)" v-model="dataModal.title_jp" rules="required|min:3|max:160" />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="unit">
                    <label>Unit <span class="text-danger mr5">*</span></label>
                    <b-form-input type="number" v-model="dataModal.unit"></b-form-input>
                    <VValidate name="Unit" v-model="dataModal.unit" rules="required|min_value:1" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="submitMachine" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modalClient" title="Form Client" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormClient"
        >
          <b-form @submit.prevent="handleSubmit(submitClient)">
            <b-card-body>
              <b-row>
                <b-col md=12>
                  <div class="form-group">
                    <label class="control-label">Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="dataModal.image" type="client"/>
                    <VValidate 
                      name="Image" 
                      v-model="dataModal.image"
                      rules="required" 
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="submitClient" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modalFasilitas" size="xl"  title="Form Fasilitas Peralatan" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormFasilitas"
      >
        <b-form @submit.prevent="handleSubmit(submitFasilitas)">
          <b-card-body>
            <b-row>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Image <span class="text-danger">*</span></label>         
                  <Uploader key="image_square" v-model="dataModal.image" type="fasilitas_square"/>
                  <VValidate 
                    name="Image" 
                    v-model="dataModal.image"
                    rules="required" 
                  />
                </div>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_id"></b-form-input>
                      <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:160" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_en"></b-form-input>
                      <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:160" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_jp"></b-form-input>
                      <VValidate name="Title (JP)" v-model="dataModal.title_jp" rules="required|min:3|max:160" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="submitFasilitas" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalFlagship" size="xl"  title="Form Flagship Product" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormFlagship"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitFlagship)">
          <b-card-body>
            <b-row>
              <b-col md=4>
                <div class="form-group">
                    <label class="control-label">Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="dataModal.image" type="transmission_gear"/>
                    <VValidate 
                      name="Image" 
                      v-model="dataModal.image"
                      rules="required" 
                    />
                </div>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=6>
                    <b-form-group label-for="titleId">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_id"></b-form-input>
                      <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:160" />
                    </b-form-group>
                    <b-form-group label-for="descId">
                      <label>Description (ID)</label>
                      <b-form-textarea v-model="dataModal.desc_id"></b-form-textarea>
                      <VValidate name="Description (ID)" v-model="dataModal.desc_id" rules="" />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group label-for="titleEN">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_en"></b-form-input>
                      <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:160" />
                    </b-form-group>
                    <b-form-group label-for="descEN">
                      <label>Description (EN)</label>
                      <b-form-textarea v-model="dataModal.desc_en"></b-form-textarea>
                      <VValidate name="Description (EN)" v-model="dataModal.desc_en" rules="" />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group label-for="titleJP">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_jp"></b-form-input>
                      <VValidate name="Title (JP)" v-model="dataModal.title_jp" rules="required|min:3|max:160" />
                    </b-form-group>
                    <b-form-group label-for="descJP">
                      <label>Description (JP)</label>
                      <b-form-textarea v-model="dataModal.desc_jp"></b-form-textarea>
                      <VValidate name="Description (JP)" v-model="dataModal.desc_jp" rules="" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="doSubmitFlagship" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>

let _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
  },
  data(){
    return {
      dataModal: {},
      imageType: [
        {value: 'L', label: 'Landscape'},
        {value: 'S', label: 'Square'}
      ]
    }
  },
  methods:{
    addFlagshipProduct(){
      this.dataModal = { desc_id: '', desc_en: '', desc_jp: '' }
      this.$bvModal.show('modalFlagship')
    },
    updateFlagship(value, index){
      this.dataModal = _.clone(value)
      this.dataModal.index = index
      this.dataModal.isUpdate = true
      this.$bvModal.show('modalFlagship')
    },
    doSubmitFlagship(){
      this.$refs.VFormFlagship.validate().then(success => {
        if(!success) return

        this.$parent.swalLoading()

        if(this.dataModal.isUpdate){
          const index = this.dataModal.index
          delete this.dataModal.index
          delete this.dataModal.isUpdate
          this.row.mlp_content_gear[index] = this.dataModal
          this.dataModal = {}
        }else{
          console.log('ADD')
          if(!this.row.mlp_content_gear) this.row.mlp_content_gear = []
          this.row.mlp_content_gear.push(this.dataModal)
          this.dataModal = {}
        }

        this.$bvModal.hide('modalFlagship')

        this.$swal.close()

      })
    },
    deleteFlagship(index){
      this.$swal({
        title: 'Delete this Flagship Product?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value){
          this.row.mlp_content_gear.splice(index, 1)
        }
      })
    },
    createSlug(value, lang){
      if(lang == 'id'){
        this.row.mlp_slug_id = value.slugify()
      }else if(lang == 'en'){
        this.row.mlp_slug_en = value.slugify()
      }else{
        this.row.mlp_slug_jp = value.slugify()
      }
    },
    changeImageType(){
      this.dataModal.image = null
    },
    addImage(){
      this.dataModal = {}
      this.$bvModal.show('modalImage')
    },
    editImage(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalImage')
    },
    deleteImage(index){
      this.row.mlp_images.splice(index, 1)
    },
    submitImage(){
      this.$refs.VFormImage.validate().then(success => {
        if(!success) return

        if(!this.row.mlp_images) this.row.mlp_images = []
        if(this.dataModal.id){
          // UPDATE
          const index = this.row.mlp_images.findIndex(v => v.id == this.dataModal.id)
          if(index > -1){
            this.$set(this.row.mlp_images, index, this.dataModal)
          }
        }else{
          // ADD
          this.dataModal.id = Math.floor(Math.random() * 999)
          this.row.mlp_images.push(this.dataModal)
        }
        this.$bvModal.hide('modalImage')
      })
    },
    addMass(){
      this.dataModal = {}
      this.$bvModal.show('modalMass')
    },
    editMass(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalMass')
    },
    submitMass(){
      this.$refs.VFormMass.validate().then(success => {
        if(!success) return
        if(this.dataModal.id){
          // UPDATE
          const index = this.row.mlp_content_development.mass.lists.findIndex(v => v.id == this.dataModal.id)
          if(index > -1){
            this.$set(this.row.mlp_content_development.mass.lists, index, this.dataModal)
          }
        }else{
          // ADD
          this.dataModal.id = Math.floor(Math.random() * 999)
          this.row.mlp_content_development.mass.lists.push(this.dataModal)
        }
        this.$bvModal.hide('modalMass')
      })
    },
    deleteMass(index){
      this.row.mlp_content_development.mass.lists.splice(index, 1)
    },
    addMachine(){
      this.dataModal = {}
      this.$bvModal.show('modalMachine')
    },
    editMachine(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalMachine')
    },
    submitMachine(){
      this.$refs.VFormMachine.validate().then(success => {
        if(!success) return
        if(this.dataModal.id){
          // UPDATE
          const index = this.row.mlp_content_machine.lists.findIndex(v => v.id == this.dataModal.id)
          if(index > -1){
            this.$set(this.row.mlp_content_machine.lists, index, this.dataModal)
          }
        }else{
          // ADD
          this.dataModal.id = Math.floor(Math.random() * 999)
          this.row.mlp_content_machine.lists.push(this.dataModal)
        }
        this.$bvModal.hide('modalMachine')
      })
    },
    deleteMachine(index){
      this.row.mlp_content_machine.lists.splice(index, 1)
    },
    addClient(){
      this.dataModal = {}
      this.$bvModal.show('modalClient')
    },
    editClient(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalClient')
    },
    submitClient(){
      this.$refs.VFormClient.validate().then(success => {
        if(!success) return
        if(this.dataModal.id){
          // UPDATE
          const index = this.row.mlp_content_client.images.findIndex(v => v.id == this.dataModal.id)
          if(index > -1){
            this.$set(this.row.mlp_content_client.images, index, this.dataModal)
          }
        }else{
          // ADD
          this.dataModal.id = Math.floor(Math.random() * 999)
          this.row.mlp_content_client.images.push(this.dataModal)
        }
        this.$bvModal.hide('modalClient')
      })
    },
    deleteClient(index){
      this.row.mlp_content_client.images.splice(index, 1)
    },
    addFasilitas(){
      this.dataModal = {}
      this.$bvModal.show('modalFasilitas')
    },
    editFasilitas(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalFasilitas')
    },
    deleteFasilitas(index){
      this.row.mlp_content_fasilitas.lists.splice(index, 1)
    },
    submitFasilitas(){
      this.$refs.VFormFasilitas.validate().then(success => {
        if(!success) return

         if(this.dataModal.id){
            // UPDATE
            const index = this.row.mlp_content_fasilitas.lists.findIndex(v => v.id == this.dataModal.id)
            if(index > -1){
              this.$set(this.row.mlp_content_fasilitas.lists, index, this.dataModal)
            }
          }else{
            // ADD
            this.dataModal.id = Math.floor(Math.random() * 999)
            this.row.mlp_content_fasilitas.lists.push(this.dataModal)
          }
          this.$bvModal.hide('modalFasilitas')
      })
    },
    doSubmitForm(){
      this.$emit('click:doSubmit', 'VForm', this.$refs)
    },
  }
}
</script>
<style scoped>
.wrap_info_transmission {
    position: relative;
    padding: 15px;
    min-height: 209px;
}
.bg_red {
    background-color: #c92027 !important;
}
.wrap_info_transmission h3 {
    padding: 0 0 15px 0;
    margin: 0;
    font-size: 22px;
    color: #fff;
    font-weight: 600;
}
.wrap_info_transmission p {
    padding: 0;
    margin: 0;
    font-size: 15px;
    color: #fff;
}
.wrap_info_transmission ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
}
.wrap_info_transmission ul li {
    padding: 0 0 5px 0;
    list-style-type: disc;
    margin: 0;
    font-size: 15px;
    color: #fff;
    display: inline-block;
    width: calc(100% / 2);
}
.wrap_info_transmission ul li i {
    float: left;
    margin-right: 5px;
}
.bg_green_ic {
    background-color: #39768e;
}
.facilities_wrap {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}
.thumb_prod {
    transition: transform 0.5s;
}
.overlay_facilities {
    position: absolute;
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    left: 0;
    bottom: 0;
}
.bg_red_ic {
    background-color: #c92027;
}
.bg_green_ic {
    background-color: #39768e;
}
.overlay_facilities h3 {
    padding: 0;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}
.wrap_fasilitas_action {
  position: absolute;
  z-index: 8;
  top: 16px;
  right: 16px;
  display: flex;
}
.wrap_fasilitas_action .btn {
  margin-left: 8px;
}
.facilities_wrap .badge {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 8;
}
</style>