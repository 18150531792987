<template>
  <b-container fluid>
    <bo-page-title />
    
    <bo-card v-if="isList" :title="pageTitle+' List'" use-table >
      <template #filters>
        <b-form-row class="justify-content-end">
          <b-col md="3">
            <v-select 
              placeholder="All Status" 
              v-model="filter.status" 
              :options="Config.mr.StatusOptions" :clearable="true" 
              :reduce="v=>v.value" label="text" 
              @input="doFilter()" 
            />
          </b-col>
          <b-col md="auto">
            <b-form @submit.prevent="doFilter()" class="d-flex">
              <b-input-group>
              <b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
              <b-input-group-append>
                <b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
              </b-input-group-append>
              </b-input-group>
              <b-button
                class="ml-1 d-inline-flex align-items-center"
                variant="outline-success"
                @click="doReset()"
              >Reset</b-button>
            </b-form>
          </b-col>
        </b-form-row>
      </template>
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :per-page="perPage"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        
        <template #cell(title)="data">
          {{data.item.mlp_title_id}} / {{data.item.mlp_title_en}} / {{data.item.mlp_title_jp}}
        </template>

        <template #cell(mlp_thumbnail)="v">
          <img :src="uploader(v.value)" width="60" alt="">
        </template>

        <template
          #cell(mlp_is_active) = "data"
        >
          <b-badge
            v-if="data.value=='Y'"
            variant="success"
          >Active</b-badge>
          <b-badge
            v-else
            variant="danger"
          >Inactive</b-badge>
        </template>
        <template
          #cell(action)="data"
        >
          <b-button
            v-if="moduleRole('edit')"

            v-b-tooltip.hover
            title="Edit"
            class="btn-icon"
            variant="outline-primary"
            size="sm"
            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
          >
            <i class="ti-marker-alt"></i>
          </b-button>
          <b-button
            v-if="moduleRole('changeStatus')"

            v-b-tooltip.hover
            title="Change Status"
            class="btn-icon"
            variant="outline-info"
            size="sm"
            @click="doChangeStatus(data.index, data.item)"
          >
            <i class="ti-settings"></i>
          </b-button>
          <b-button
            v-if="moduleRole('delete')"

            v-b-tooltip.hover
            title="Delete"
            class="btn-icon"
            variant="outline-danger"
            size="sm"
            @click="doDelete(data.index, data.item)"
          >
            <i class="ti-trash"></i>
          </b-button>

          <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
        </template>

      </b-table>
      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
        <b-pagination
        class="mb-0"
        v-model="pageNo"
        :per-page="data.per_page"
        :total-rows="data.total"
        />
      </b-card-footer>
    </bo-card>

    <template v-else>
      <Form :row.sync="row" v-bind="passToSub" />
    </template>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'mlp_id',
      statusKey:'mlp_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        { 
          key: 'title', 
          label:'Title', 
        },
        { 
          key: 'mlp_thumbnail', 
          label:'Thumbnail', 
        },
        {
          key: 'mlp_is_active',
          label: 'Status',
        },
        'action',
      ],
    }
  },
  computed: {
    passToSub(){
      return Object.assign(this.passToSubComp)
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>